<template>
    <div class="c-widget--gallery c-gallery c-gallery--hero">
        <siema class="c-gallery__slider" ref="gallery" :options="options" :current.sync="curSlide">
            <router-link :to="getLinkPath(slide.post.post_name).pathname" v-for="(slide, i) in data.slides" class="c-tease c-tease--hero" :key="i">
                <div class="c-tease__content c-tease__content--hero">
                    <h2 class="c-tease__title c-tease__title--hero">{{ slide.headline }}</h2>
                    <h3 class="c-tease__subtitle c-tease__subtitle--hero">{{ slide.label }}</h3>
                </div>
                <div class="c-tease__img c-tease__img--hero" v-if="slide.image" :style="{ backgroundImage: `url(${ slide.image.sizes.large })`, backgroundPosition: slide.image_position ? slide.image_position.replace(',', ' ') : null }"></div>
                <div class="c-tease__img c-tease__img--hero" v-if="!slide.image" :style="{ backgroundImage: `url(${ slide.post.featured_image.sizes.large.src[0] })`, backgroundPosition: slide.post.featured_image_position ? slide.post.featured_image_position.replace(',', ' ') : null }"></div>
            </router-link>
        </siema>

        <div ref="dots" class="c-gallery__controls c-gallery__controls--dots">
            <button v-for="(img, j) in data.slides" class="c-btn c-btn--gallery-dot"
                    :class="{ 'c-btn--gallery-dot-active' : curSlide === j }" :key="j"
                    @click="$refs.gallery.goTo(j)">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="9.407" cy="9.657" r="8.309"/></svg>
            </button>
        </div>

        <button class="c-btn c-btn--smooth-scroll" @click="$scrollTo('#widget-1')"><svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 21" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M53 2L27.5 19 2 2"/></svg></button>
    </div>
</template>

<script>
    export default {
        name: 'TopStorySlider',
        props: {
            data: Object
        },

        data () {
            return {
                curSlide: 0,
                options: {},
            }
        },

        methods: {
            getLinkPath(url) {
                const link = document.createElement('a')
                link.href = '/post/' + url
                return link
            },
        }
    }
</script>

<style scoped lang="scss" src="@/styles/components/_gallery.scss"></style>
<style scoped lang="scss" src="@/styles/components/_tease.scss"></style>